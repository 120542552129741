const menuButton = document.getElementById('dtr-menu-button');
const mobileMenuWrapper = document.getElementById('mobile-menu-wrapper');
const mobileMenuContent = document.getElementById('mobile-menu-content');
mobileMenuWrapper.style.height = `364px`;
mobileMenuWrapper.style.height = '0px'
menuButton.addEventListener('click', function () {
    if (menuButton.classList.contains('is-active')) {
        mobileMenuWrapper.style.height = '0px'
        menuButton.classList.remove('is-active');
    } else {
        menuButton.classList.add('is-active');
        mobileMenuWrapper.style.height = `364px`;
    }
});
const header = document.getElementById('dtr-header-global'); 
window.addEventListener('scroll', function () {
    if (window.scrollY >= 20) {
        header.classList.add('on-scroll');
    } else {
        header.classList.remove('on-scroll');
    }
});

partytown = {
    forward: ["dataLayer.push"]
};
